<template>
  <div>
      <vx-card>
        <h4>Resguardo de pagos.</h4>
        <p class="mt-5">
            Al dar clic en Autorizar confirmarás que RedGirasol podrá resguardar tus pagos dentro de tu saldo disponible. <strong>Podrás retirar tu saldo disponible en el momento en el que requieras</strong>.
            <br><br>Por este medio, <strong><u>{{userName}}</u></strong> en mi calidad de Comitente del Contrato de Comisión Mercantil, autorizo a Red Girasol para que mantenga en su resguardo los recursos provenientes del cobro de las mensualidades de los Proyectos en los que participo como Acreditante.
        </p>
        <template v-if="receiptPaymentsContract">
            <vs-button v-if="!receiptPaymentsContract.isSigned" class="mt-5 sm:px-base px-4" @click="signContract(receiptPaymentsContractType)"
            >Autorizar</vs-button>
            <div v-else class="sign-area mt-5">
                <h3 class="mt-5 text-primary">Tu firma:</h3>
                <h5>Datos de la firma:</h5>
                <p class="my-2"><span class="bold">Nombre del documento: </span> {{receiptPaymentsContract.metadata.document}}</p>
                <p class="my-2"><span class="bold">Versión del documento: </span> {{receiptPaymentsContract.metadata.version}}</p>
                <p class="my-2"><span class="bold">Fecha de aceptación: </span> {{signatureFormatDate(receiptPaymentsContract.metadata.acceptance_date)}}</p>
                <p class="my-2"><span class="bold">Agente: </span> {{receiptPaymentsContract.metadata.agent}}</p>
                <p class="my-2"><span class="bold">IP: </span> {{receiptPaymentsContract.metadata.ip}}</p>
                <p class="my-2"><span class="bold">Correo electrónico: </span> {{receiptPaymentsContract.metadata.email}}</p>
                <p class="my-2" v-if="receiptPaymentsContract.metadata.geolocation != null && receiptPaymentsContract.metadata.geolocation != '--'">
                  <span class="bold">Ubicación: </span> {{receiptPaymentsContract.metadata.geolocation}}
                </p>
                <h5 class="mt-5">Cadena de firma electrónica:</h5>
                <p class="text-break text-xs my-2">{{receiptPaymentsContract.signature}}</p>
            </div>
        </template>
      </vx-card>
      <vx-card class="mt-base">
        <h4>Inversión Automática (Contratación / No Activación)</h4>
        <p class="mt-5">
            Al dar clic en Autorizar confirmarás que RedGirasol podrá invertir automáticamente tus pagos en el momento en que actives la inversión automática <strong>(que se encuentra desactivado por defecto)</strong> en el apartado de “Mi forma de riego”. Podrás habilitar y deshabilitar la inversión automática en el momento en el que requieras.
            <br><br>Por este medio, <strong><u>{{userName}}</u></strong> en mi calidad de Comitente del Contrato de Comisión Mercantil, autorizo a Red Girasol para que pueda invertir automáticamente los recursos que mantengo en el Sitio en nuevos Proyectos, bajo los esquemas de riesgo y condiciones que he señalado en el Sitio.
        </p>
        <template v-if="autoInvestmentContract">
            <vs-button v-if="!autoInvestmentContract.isSigned" class="mt-5 sm:px-base px-4" @click="signContract(autoInvestmentContractType)"
            >Autorizar</vs-button>
            <div v-else class="sign-area mt-5">
                <h3 class="mt-5 text-primary">Tu firma:</h3>
                <h5>Datos de la firma:</h5>
                <p class="my-2"><span class="bold">Nombre del documento: </span> {{autoInvestmentContract.metadata.document}}</p>
                <p class="my-2"><span class="bold">Versión del documento: </span> {{autoInvestmentContract.metadata.version}}</p>
                <p class="my-2"><span class="bold">Fecha de aceptación: </span> {{signatureFormatDate(autoInvestmentContract.metadata.acceptance_date)}}</p>
                <p class="my-2"><span class="bold">Agente: </span> {{autoInvestmentContract.metadata.agent}}</p>
                <p class="my-2"><span class="bold">IP: </span> {{autoInvestmentContract.metadata.ip}}</p>
                <p class="my-2"><span class="bold">Correo electrónico: </span> {{autoInvestmentContract.metadata.email}}</p>
                <p class="my-2" v-if="autoInvestmentContract.metadata.geolocation != null && autoInvestmentContract.metadata.geolocation != '--'">
                  <span class="bold">Ubicación: </span> {{autoInvestmentContract.metadata.geolocation}}
                </p>
                <h5 class="mt-5">Cadena de firma electrónica:</h5>
                <p class="text-break text-xs my-2">{{autoInvestmentContract.signature}}</p>
            </div>
        </template>
      </vx-card>
      <vx-card class="mt-base">
        <latest-advertising-contract />
        <h5 v-if="!advertisingContract.isSigned" class="mt-5">Puedes activar estas notificaciones desde la sección "Mi perfíl - Notificaciones"</h5>
        <template v-else>
          <div class="sign-area mt-5">
            <h3 class="mt-5 text-primary">Tu firma:</h3>
            <h5>Datos de la firma:</h5>
            <p class="my-2"><span class="bold">Nombre del documento: </span> {{advertisingContract.metadata.document}}</p>
            <p class="my-2"><span class="bold">Versión del documento: </span> {{advertisingContract.metadata.version}}</p>
            <p class="my-2"><span class="bold">Fecha de aceptación: </span> {{signatureFormatDate(advertisingContract.metadata.acceptance_date)}}</p>
            <p class="my-2"><span class="bold">Agente: </span> {{advertisingContract.metadata.agent}}</p>
            <p class="my-2"><span class="bold">IP: </span> {{advertisingContract.metadata.ip}}</p>
            <p class="my-2"><span class="bold">Correo electrónico: </span> {{advertisingContract.metadata.email}}</p>
            <p class="my-2" v-if="advertisingContract.metadata.geolocation != null && advertisingContract.metadata.geolocation != '--'">
              <span class="bold">Ubicación: </span> {{advertisingContract.metadata.geolocation}}
            </p>
            <h5 class="mt-5">Cadena de firma electrónica:</h5>
            <p class="text-break text-xs my-2">{{advertisingContract.signature}}</p>
          </div>
        </template>
      </vx-card>
  </div>
</template>
<script>
import dateHelper from "@mixins/dateHelper";
import LatestAdvertisingContract from "@views/share/contracts/AdvertisingContract/LatestAdvertisingContract.vue";

export default {
  name: "ConsentsPage",
  mixins: [dateHelper],
  components: {
    LatestAdvertisingContract
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getContractsData();
    this.isMounted = true;
    if('geolocation' in navigator) {
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    }
    this.showLoading(false)
  },
  data() {
    return {
        contractsToShow: [
            {type: 17 },
            {type: 18 },
        ],
        allContracts: [],
        currentLocation:null,
        locationOptions: {
            enableHighAccuracy: true,
            timeout: Infinity,
            maximumAge: 0
        },
        receiptPaymentsContractType: 17,
        autoInvestmentContractType: 18,
        advertisingContractType: 22,
    }
  },
  computed:{
      receiptPaymentsContract(){
          return this.allContracts.find(f => f.type === this.receiptPaymentsContractType);
      },
      autoInvestmentContract(){
          return this.allContracts.find(f => f.type === this.autoInvestmentContractType);
      },
      advertisingContract(){
          return this.allContracts.find(f => f.type === this.advertisingContractType);
      },
      userName: function () {
        return (this.user === null) ? "-" : this.user.complete_name;
      },
  },
  methods:{
    async getContractsData(){
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getContracts`);
        const defaultContracts = res.data.default;
        const invContracts = res.data.investor;
        defaultContracts.forEach(df => {
            const iinv = invContracts.find(f => f.contract.type === df.type);
            if(iinv != null){
                this.allContracts.push({
                name: df.name,
                type: df.type,
                isSigned: (iinv.signature != null),
                signature: iinv.signature ?? null,
                metadata: iinv.metadata ?? null,
                })
            }
            else {
                this.allContracts.push({
                name: df.name,
                type: df.type,
                isSigned: false,
                signature: null,
                metadata: null
                })
            }
        });
      }
      catch (e) {
      }
    },
    async signContract(contractType) {
      this.showLoading(true);
      try {
        let geolocation = null;
        if(this.currentLocation){
          geolocation = "Lat: "+this.currentLocation.latitude+", Long "+this.currentLocation.longitude;
        }
        const payload = { contractType: contractType, geolocation: geolocation}
        const res = await axios.post(`/api/v2/investor/${this.InvestorId}/signContract`,payload);
        if(res){
          this.$router.go();
        }
      } catch (error) {
        this.showLoading(false);
        this.errorNotif("Aviso", "Ha ocurrido un error", 4000);
      }
    },
    async onSuccessLocation(pos){
      this.currentLocation = pos.coords;
    },
  }
};
</script>